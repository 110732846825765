<template lang="pug">
b-form(@submit.stop.prevent="onSubmit")
  .subtitle
    h4 Reiniciar contraseña
    p
      | Nueva contraseña para usuario
      br
      | {{ email }}

  .form-group
    .icon-container
      b-icon(icon="lock")
    b-form-input.form-control(
      v-model="password"
      :type="password ? 'password' : 'text'"
      placeholder="Contraseña"
      :state="validateState('password')"
    )
    b-form-invalid-feedback Contraseña requerida

  .form-group
    .icon-container
      b-icon(icon="lock")
    b-form-input.form-control(
      v-model="passwordRepeat"
      :type="passwordRepeat ? 'password' : 'text'"
      placeholder="Repetir contraseña"
      :state="validateState('passwordRepeat')"
    )
    b-form-invalid-feedback La contraseña debe coincidir

  .w-100.d-inline-flex.flex-column.align-items-center
    b-button.boton(type="submit")
      | Cambiar contraseña
      b-spinner(v-if="loading" small)
</template>

<script>
import { mapActions } from 'vuex'
import { required, sameAs } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      password: '',
      passwordRepeat: '',
      loading: false,
    }
  },
  validations: {
    password: { required },
    passwordRepeat: { required, sameAsPassword: sameAs('password') },
  },
  computed: {
    email() {
      return this.$route.query?.email || ''
    }
  },
  methods: {
    ...mapActions('AuthenticationStore', ['resetRetailUserPassword']),

    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },

    onSubmit() {
      if (this.loading) return
      this.$v.$touch()
      if (this.$v.$invalid) return false
      this.loading = true

      const { user_id, token } = this.$route.query
      this.resetRetailUserPassword({ user_id, password: this.password, token })
        .then((res) => {
          this.$bvToast.toast(res.data?.message, {
            title: 'Correo enviado',
            variant: 'success',
            solid: true,
          })
          this.password = ''
          this.passwordRepeat = ''
          this.$router.push({ name: 'Login' })
        })

        .catch((err) => {
          console.log(err.response);
          const { message, detail } = err.response.data
          this.$bvToast.toast(message || detail, {
            title: 'Error',
            variant: 'danger',
            solid: true,
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
